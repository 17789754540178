<template>
  <editor
    tinymceScriptSrc="https://cdn.zionbit.eu/tinymce/tinymce.min.js"
    :init="{
      inline: true,
      plugins: ['link lists paste'],
      menubar: '',
      language: 'cs',
      force_br_newlines: false,
      forced_root_block: false,
      deprecation_warnings: false,
      entity_encoding: 'raw',
      toolbar:
        'bold italic underline | removeformat| alignleft aligncenter alignright alignjustify | fontsizeselect forecolor | link bullist |undo redo ',
    }"
    tag-name="div"
  />
</template>
<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "InlineEditor",
  components: {
    editor: Editor, // <- Important part
  },
};
</script>
<style lang="scss">
.ib {
  display: inline-block;
}
</style>
