<template v-if="data">
  <div class="text container mt-5">
    <div
      v-if="savedNotification"
      class="position-fixed top-0 end-0 p-2"
      style="z-index: 11"
    >
      <div class="toast align-items-center text-white bg-success border-0 show">
        <div class="d-flex">
          <div class="toast-body"><b>Obsah uložen!</b></div>
        </div>
      </div>
    </div>
    <h2 class="title" v-if="$editor && form.title">
      <InlineEditor class="description" @input="save" v-model="form.title" />
    </h2>
    <h2 class="title" v-else-if="data.title">{{ data.title }}</h2>
    <p class="text-black-50">{{ formatDate(data.publishDate) }}</p>
    <p
      v-if="perexLength > 0"
      class="description"
      v-html="truncate(data.text, perexLength)"
    ></p>

    <InlineEditor
      class="description"
      v-else-if="$editor"
      @input="save"
      v-model="form.text"
    />

    <p v-else class="description" v-html="data.text"></p>
  </div>
</template>
<script>
import "./Text.scss";
import { truncate } from "../../helpers/truncate";
import { formatDate } from "../../helpers/formatDate";
import { updateContent } from "../../api/api.js";
import InlineEditor from "../InlineEditor.vue";

export default {
  name: "Text",
  components: {
    InlineEditor,
  },
  data() {
    return {
      form: null,
      savedNotification: false,
    };
  },
  props: {
    data: Object,
    perexLength: Number,
  },
  created() {
    if (this.$editor) {
      this.form = { ...this.data };
    }
  },
  methods: {
    truncate,
    formatDate,
    save(content) {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.saveAction, 1500, content);
    },
    async saveAction(content) {
      if (
        await updateContent("text", this.data.cmsid, {
          text: { [this.$lang]: content.target.innerHTML },
        })
      ) {
        this.savedNotification = true;
      } else {
        this.failedNotification = true;
      }

      setTimeout(() => {
        this.savedNotification = false;
        this.failedNotification = false;
      }, 3000);
    },
  },
};
</script>
