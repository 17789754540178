<template v-if="data">
  <div>
    <Text
      v-if="data.schemaName == 'text'"
      :data="data"
      :perexLength="perexLength"
    />

    <Image v-if="data.schemaName == 'image'" :data="data" />

    <template v-if="data.schemaName == 'gallery'">
      <Image v-if="data.type == 'carousel'" :data="data" />
      <Gallery v-else :data="data" />
    </template>

    <Product v-else-if="data.schemaName == 'product'" :data="data" />

    <Contact v-else-if="data.schemaName == 'contact'" :data="data" />

    <template v-else-if="data.schemaName == 'group'">
      <Boxes v-if="data.type == 'boxes'" :data="data" />
      <General
          v-else
          v-for="(item, j) in data.content"
          v-bind:key="j"
          :data="item"
          :perexLength="data.perexLength"
        />
    </template>
  </div>
</template>

<script>
import General from "./General.vue";
import Text from "../Text/Text.vue";
import Image from "../Image/Image.vue";
import Gallery from "../Gallery/Gallery.vue";
import Contact from "../Contact/Contact.vue";
import Product from "../Product/Product.vue";
import Boxes from "../Boxes/Boxes.vue";

import "./General.scss";

export default {
  name: "General",
  components: {
    Text,
    Image,
    Gallery,
    General,
    Product,
    Contact,
    Boxes
  },
  data() {
    return {};
  },
  props: {
    data: Object,
    perexLength: Number
  },
  methods: {
    log(msg) {
      console.log(msg);
    },
  }
};
</script>
